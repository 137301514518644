import "react-toastify/dist/ReactToastify.css";

import { GlobalStyle, Main } from "@styles/globalStyles";
import React, { ReactNode } from "react";
import { checkURLParams, usePageContext } from "@util/hooks";

import { ToastContainer } from "react-toastify";

interface Props {
  children: ReactNode;
}

function Layout(props: Props) {
  const { children } = props;

  checkURLParams();
  usePageContext(children);

  return (
    <div>
      <GlobalStyle />
      {/* <Header /> */}
      {/* <Status /> */}
      <Main>{children}</Main>
      <ToastContainer position="top-right" />

      {/* <Footer /> */}
      <div id="tooltip-root"></div>
    </div>
  );
}

export default Layout;
