import {
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  mediaQueryWidth,
} from "./constants";
import {
  Maybe,
  SanityAudio,
  SanityDragDropGroup,
  SanityGlobalQuizAnswer,
  SanityGlobalQuizQuestion,
  SanityGoalGroup,
  SanityHero,
  SanityHorizontalDragDrop,
  SanityPage,
} from "@graphql-types";
import { MediaQueryWidth, PersonalityScoreData, ScreenWidth } from "./types";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { add30Days, isBrowser, synthesizeTextToSpeech } from "./helper";

import { GlobalQuiz } from "@state/types";
import axios from "axios";
import moment from "moment";
import { sanityClient } from "@lib/sanityClient";
import { toPlainText } from "@portabletext/react";
import { toast } from "react-toastify";
import { useFirebaseUpdateUser } from "./useFirestoreDocFuncs";
import { useFirestoreDocFuncs } from "./useFirestoreDocFuncs";
import { useGetResults } from "./groqHooks";
import { useReturnSocialPollPercentage } from "@state/logicHooks";
import { useStore } from "@state/store";

const defaultScreenWidth = {
  isTabletWidth: false,
  isMobileWidth: false,
  isLaptopWidth: false,
};

// get previous const for comparsion
export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

// similar to use previous but will do an comparsion between prev & next prop
export function usePreviousCompare<T>(next: T, compare: (prev: T, next: T) => T) {
  const previousRef = useRef<T>(next);
  const previous = previousRef.current;

  const isEqual = compare(previous, next);

  useEffect(() => {
    if (previousRef.current == null) {
      return;
    }

    if (!isEqual && previousRef.current) {
      previousRef.current = next;
    }
  });

  return isEqual ? previous : next;
}

// check if component has been mounted
export function useHasMounted() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  if (!mounted) {
    return false;
  }

  return mounted;
}

// the same as useState but will store the value in localStorage
function useStorage<T>(key: string, defaultValue: T | (() => T), storage: Storage) {
  const [value, setValue] = useState(() => {
    const jsonValue = storage.getItem(key);
    if (jsonValue != null) return JSON.parse(jsonValue);

    if (defaultValue instanceof Function) {
      return defaultValue();
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (value === undefined) return storage.removeItem(key);
    storage.setItem(key, JSON.stringify(value));
  }, [key, value, storage]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return [value, setValue, remove];
}

export function useLocalStorage<T>(key: string, defaultValue: T | (() => T)) {
  if (!isBrowser()) return;
  return useStorage(key, defaultValue, window.localStorage);
}

export function useSessionStorage<T>(key: string, defaultValue: T | (() => T)) {
  if (!isBrowser()) return;
  return useStorage(key, defaultValue, window.sessionStorage);
}

export function useIsEmptyObject(obj: any) {
  return Object.keys(obj).length === 0;
}

// event listener hook
export function useEventListener(
  eventName: string,
  handler: (args: any) => void,
  elementToListen?: any,
) {
  if (!isBrowser()) return;
  const element = elementToListen ?? window;

  const savedHandler = useRef<typeof handler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    //@ts-ignore
    const eventListener = (event: any) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);

    return () => element.removeEventListener(eventName, eventListener);
  }, [eventName, element]);
}

// set dark dark mode
export function useDarkMode(refObject?: RefObject<any>) {
  if (!isBrowser()) {
    return;
  }
  const [enabled, setEnabled] = useState(false);
  const element = refObject?.current ?? window.document.body;

  useEffect(() => {
    const className = "dark-mode";

    if (element) {
      if (enabled) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
    }
  }, [enabled]);

  return [enabled, setEnabled];
}

// console logs the state when it gets updated
export function useUpdateLogger(value: any) {
  useEffect(() => {}, [value]);
}

// changes the boolean value to it's opposite value
export function useToggle(initialState = false): [boolean, () => void] {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(state => !state), []);

  return [state, toggle];
}

// timeout hook, returns reset and clear function
export function useTimeout(callback: (args?: any) => void, delay: number) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<any>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  useEffect(() => {
    return () => clear();
  }, []);

  return { reset, clear };
}

// debounce hook - run a callback after a certain delay
export function useDebounce(callback: (args?: any) => void, delay: number, dependencies: any[]) {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, []);
}

export function useArray(defaultValue: any[]) {
  const [array, setArray] = useState(defaultValue);

  const push = (element: any) => {
    setArray(a => [...a, element]);
  };

  const filter = (callback: (args?: any) => void) => {
    setArray(a => a.filter(callback));
  };

  const update = (index: number, newElement: any) => {
    setArray(a => [...a.slice(0, index), newElement, ...a.slice(index + 1, a.length)]);
  };

  const remove = (index: number) => {
    setArray(a => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
  };

  const clear = () => {
    setArray([]);
  };

  return { array, set: setArray, push, filter, update, remove, clear };
}

// counts the number of re-renders
export function useRenderCount() {
  const count = useRef(1);
  useEffect(() => {
    count.current++;
  }, []);
  return count.current;
}

// media query hook
export default function useMediaQuery(mediaQuery: string) {
  const [isMatch, setIsMatch] = useState(false);
  const [mediaQueryList, setMediaQueryList] = useState<MediaQueryList | null>(null);

  useEffect(() => {
    if (!isBrowser()) return;
    const list = window.matchMedia(mediaQuery);
    setMediaQueryList(list);
    setIsMatch(list.matches);
  }, [mediaQuery]);

  useEventListener("change", e => setIsMatch(e.matches), mediaQueryList);

  return isMatch;
}

// checks screen width
export function useCheckScreenWidth(): ScreenWidth {
  if (!isBrowser()) {
    return defaultScreenWidth;
  }
  const [screenWidth, setScreenWidth] = useState(defaultScreenWidth);
  const hasMounted = useHasMounted();

  const checkScreenWidth = () => {
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
        isTabletWidth: true,
        isMobileWidth: true,
      });
      return;
    }
    if (window.innerWidth <= TABLET_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
        isTabletWidth: true,
      });
      return;
    }
    if (window.innerWidth <= LAPTOP_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
      });
      return;
    }
    if (window.innerWidth > LAPTOP_BREAKPOINT) {
      setScreenWidth(defaultScreenWidth);
      return;
    }
  };

  useEventListener("resize", checkScreenWidth);

  useEffect(() => {
    checkScreenWidth();
  }, []);

  useEffect(() => {
    if (hasMounted) {
      checkScreenWidth();
    }
  }, [hasMounted]);

  return screenWidth;
}

export const useConditionalVisibility = (
  question: SanityGlobalQuizQuestion,
  quizRef: Maybe<string> | undefined,
) => {
  const { globalQuiz } = useStore();
  if (!question || !globalQuiz) return [];

  const globalQuizRef = globalQuiz.find(quiz => quiz.id === quizRef);
  if (!globalQuizRef) return [];

  return (
    question.answers?.reduce((visibleAnswers, answer) => {
      const conditions = answer?.conditionalVisibility;
      if (!conditions || !conditions.length) {
        visibleAnswers.push(answer);
        return visibleAnswers;
      }

      console.log({ conditions });

      const isAnswerVisible = conditions.some(condition => {
        if (!condition?.references || !condition?.references.length) return false;

        return condition.references.every(reference => {
          const conditionalQuestion = globalQuizRef.questions?.find(
            q => q._id === reference?.selectedQuestionKey,
          );
          console.log({ conditionalQuestion });

          return conditionalQuestion?.selectedAnswer._key === reference?.selectedAnswerKey;
        });
      });

      console.log({ isAnswerVisible });

      if (isAnswerVisible) visibleAnswers.push(answer);
      return visibleAnswers;
    }, []) || []
  );
};

export const useConditionalFortuneDestination = (
  quizRef: Maybe<string> | undefined,
  conditionalDestinations: any,
  globalQuiz?: GlobalQuiz[],
) => {
  if (globalQuiz == null) return;

  const globalQuizRef = globalQuiz.find(quiz => quiz.id === quizRef);
  let destinationPage: Maybe<SanityPage> | undefined = undefined;
  let shouldNavigate = false;

  conditionalDestinations.map(destination => {
    if (destination == null) return;
    //Map references
    destinationPage = destination.destination;
    let shouldShowArray = [];

    destination?.references?.map(reference => {
      const conditionalQuestion =
        globalQuizRef &&
        globalQuizRef.questions?.find(questions => questions._id == reference?.selectedQuestionKey);

      if (conditionalQuestion?.selectedAnswer._key == reference?.selectedAnswerKey) {
        shouldShowArray.push(true);
      }
    });
    if (shouldShowArray.length == destination?.references?.length) {
      return (shouldNavigate = true);
    }
  });

  return shouldNavigate ? destinationPage : null;
};

export const useConditionalDestination = (
  quizRef: Maybe<string> | undefined,
  answer: SanityGlobalQuizAnswer,
  globalQuiz?: GlobalQuiz[],
) => {
  if (globalQuiz == null) return;

  const globalQuizRef = globalQuiz.find(quiz => quiz.id === quizRef);
  if (answer.conditionalDestinations == null || answer.conditionalDestinations.length == 0) {
    if (answer.destinationPage) return answer.destinationPage;

    return null;
  }

  let destinationPage: Maybe<SanityPage> | undefined = undefined;
  let shouldNavigate = false;

  answer.conditionalDestinations.map(destination => {
    if (destination == null) return;
    //Map references
    destinationPage = destination.destination;
    let shouldShowArray = [];
    destination?.references?.map(reference => {
      const conditionalQuestion =
        globalQuizRef &&
        globalQuizRef.questions?.find(questions => questions._id == reference?.selectedQuestionKey);

      if (conditionalQuestion?.selectedAnswer._key == reference?.selectedAnswerKey) {
        shouldShowArray.push(true);
      }
    });

    if (shouldShowArray.length == destination?.references?.length) {
      return (shouldNavigate = true);
    }
  });

  return shouldNavigate ? destinationPage : null;
};

const countPassFail = arr =>
  arr.reduce((acc, curr) => acc + (curr.selectedAnswer.passFail === true ? 1 : 0), 0);

export const useResultsPage = (
  results: any[] | undefined,
  quizRef: Maybe<string> | undefined,
  globalQuiz?: GlobalQuiz[],
) => {
  if (results == null || globalQuiz == null) return;
  const globalQuizRef = globalQuiz.find(quiz => quiz.id === quizRef);

  //filter outcomes
  if (results.length == 0) {
    toast.error("No Result pages have been setup for this quiz");
    return;
  }
  const selectedOutcomes = results.find(item => item.id == quizRef);

  const outcomes = selectedOutcomes.outcomes;

  const passFailRatio =
    globalQuizRef?.scoringOption == "passFail" ? countPassFail(globalQuizRef?.questions) : "not";

  console.log("passFailRatio", passFailRatio);

  const currentScore =
    globalQuizRef?.scoringOption == "passFail" ? passFailRatio : globalQuizRef?.score;

  if (currentScore == null) {
    toast.error("The user currently has no score and has answered no questions");
    return;
  }

  let destinationPage: Maybe<SanityPage> | undefined = undefined;

  //setup conditions
  outcomes.map((outcome: any) => {
    if (outcome == null) return;

    switch (outcome.scoreOutcome) {
      case "above":
        if (currentScore > outcome.minimumScore && destinationPage == undefined) {
          destinationPage = outcome.outcomePage;
          return;
        }

        break;

      case "below":
        if (currentScore < outcome.maximumScore && destinationPage == undefined) {
          destinationPage = outcome.outcomePage;
          return;
        }

        break;

      case "range":
        if (
          currentScore <= outcome.rangeTop &&
          currentScore >= outcome.rangeBottom &&
          destinationPage == undefined
        ) {
          destinationPage = outcome.outcomePage;
          return;
        }
        break;

      case "score":
        if (currentScore === outcome.fixedScore && destinationPage == undefined) {
          destinationPage = outcome.outcomePage;
          return;
        }
        break;

      default:
        break;
    }

    return destinationPage;
  });

  return destinationPage;
};

export const useDragDropLength = (items: any) => {
  const [length, setLength] = useState(0);

  useEffect(() => {
    let l = 0;
    items.map(item => {
      if (item?.assets) {
        l = l += item.assets.length;
      }
    });
    setLength(l);
  }, []);

  useEffect(() => {
    console.log({ length });
  }, [length]);

  return length;
};

export const useAudio = (
  data: Maybe<SanityAudio> | undefined,
  autoPlay?: boolean,
  togglePlay?: boolean,
) => {
  if (data == null) return;
  const url = data.audioFile?.asset?.url;
  if (url == null) return;

  const [audioFile] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => {
    console.log("toggled");
    setPlaying(!playing);
  };

  useEffect(() => {
    console.log(playing);

    playing ? audioFile.play() : audioFile.pause();
  }, [playing]);

  useEffect(() => {
    audioFile.addEventListener("ended", () => setPlaying(false));
    return () => {
      audioFile.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  useEffect(() => {
    console.log({ audioFile });
    if (audioFile) {
      if (data?.timerDelay) {
        setTimeout(toggle, data?.timerDelay * 1000);
      }
    }
  }, [audioFile]);

  return [playing, toggle, audioFile];
};

export const useGetGoalsResultData = () => {
  const [goalsTableData, setGoalsTableData] = useState<SanityGoalGroup[]>();

  const fetchData = async () => {
    const fetch = await sanityClient.fetch(`*[_type == "goalGroup"]`);
    setGoalsTableData(fetch);
    return fetch;
  };

  useEffect(() => {
    if (!goalsTableData) {
      fetchData();
    }
  }, []);

  return goalsTableData;
};

export const audio = isBrowser() && new Audio();

export const playAudio = (audioURL: string, timerDelay: Maybe<number> | undefined) => {
  audio.pause();
  audio.src = audioURL;

  if (timerDelay) {
    setTimeout(() => {
      audio.play();
    }, timerDelay * 1000);
  } else {
    audio.play();
  }
};

export const stopAudio = () => {
  audio.pause();
  audio.currentTime = 0;
};

export const checkURLParams = () => {
  if (!isBrowser()) return;
  const params = new URLSearchParams(window.location.search);
  const usernameParam = params.get("username");

  const { userName, setUserName } = useStore();

  useEffect(() => {
    if (userName == null && usernameParam) {
      setUserName && setUserName(usernameParam);
    }
  }, []);

  return;
};

/**
 *  Check if page has completely loaded
 * @returns
 */
export function usePageLoaded() {
  if (!isBrowser()) return;
  const [shouldLoad, setShouldLoad] = useState(false);

  const handleShouldLoad = () => setShouldLoad(true);

  useEffect(() => {
    if (document.readyState === "complete") {
      handleShouldLoad();
      return;
    }

    window.addEventListener("load", handleShouldLoad);

    return () => window.removeEventListener("load", handleShouldLoad);
  }, []);

  return shouldLoad;
}

/**
 * Set page context to state
 */
export function usePageContext(children: any) {
  const { setPageContext, setCurrentSlugRoot, setCompleted } = useStore();
  const [prevChildren, setPrevChildren] = useState<any>();
  const { user } = useStore();

  useEffect(() => {
    if (children === null) return;

    // Set global state
    const { props } = children;
    const pageObj = props.children.find((child: any) => child?.props?.pageContext);
    if (pageObj) {
      setPageContext(pageObj.props.pageContext);
      const { pageContext } = pageObj.props;

      if (pageObj.props.pageContext.workshopContext) {
        let rootPath;

        if (pageObj.props.pageContext.workshopContext?.workshop?.slug) {
          rootPath = `${pageContext?.workshopContext?.workshop?.slug}`;
        }
        if (pageContext?.workshopContext?.section?.slug) {
          rootPath = rootPath + `/${pageContext?.workshopContext?.section?.slug}`;
        }

        if (rootPath) {
          setCurrentSlugRoot(rootPath);
        }
      }

      const sectionContext = {
        workshop_id: pageContext?.workshopContext?.workshop?.id,
        section_id: pageContext?.workshopContext?.section?.id,
        page_id: pageContext?.pageId,
        template: pageContext?.template,
        isLastPageInSection: pageContext?.isLastPageInSection,
        isLastSectionInWorkshop: pageContext?.isLastSectionInWorkshop,
        isBadgePage: pageContext?.isBadgePage,
      };

      // Set Firebase state with completed pages
      if (prevChildren) {
        console.log({ prevChildren });
      }

      if (prevChildren && prevChildren.template == "page") {
        // set();

        setCompleted(sectionContext);
      }
      setPrevChildren(sectionContext);
    }
  }, [children]);
}
// Personality quiz scoring
const countAndSortArrayElements = obj =>
  Object.entries(Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: obj[key].length }), {}))
    .sort((a, b) => b[1] - a[1])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const usePersonalityQuiz = () => {
  const [scoreState, setScoreState] = useState<PersonalityScoreData[]>([]);
  const [resultObject, setResultObject] = useState({});
  const { user, setUser } = useStore();

  const onRequestStatus = {
    success: () => {},
    failed: () => {},
  };

  const { update } = useFirestoreDocFuncs(
    "users",
    user?.uid,
    { personalityScore: resultObject },
    onRequestStatus,
  );

  useEffect(() => {
    if (resultObject) {
      update();
      setUser({ ...user, personalityScore: resultObject });
    }
  }, [resultObject]);

  const updateScore = (scoreObject: any) => {
    if (scoreState.length == 0) {
      setScoreState([scoreObject]);
      return;
    }
    const filteredArray = scoreState.filter(item => item.id !== scoreObject.id);
    setScoreState([...filteredArray, scoreObject]);
  };

  const calculateScore = () => {
    const result = {};

    scoreState.forEach(item => {
      if (item.score === true) {
        if (!result[item.personalityType]) {
          result[item.personalityType] = [];
        }
        result[item.personalityType].push(item);
      }
    });

    console.log({ result });

    if (result && scoreState.length == 40) {
      const resultToFirebase = countAndSortArrayElements(result);
      setResultObject(resultToFirebase);
    }
  };

  useEffect(() => {
    calculateScore();
  }, [scoreState]);

  return { scoreState, updateScore, calculateScore, resultObject };
};

// Drag and drop hooks

export const dragDropHandler = (data: Maybe<SanityHorizontalDragDrop>, isVertical: boolean) => {
  const [droppedItems, setDroppedItems] = useState<{ [key: string]: SanityDragDropGroup | null }>(
    {},
  );
  const [isVisible, setIsVisible] = useState(false);
  const [selectedModalContent, setSelectedModalContent] = useState<any>(null);
  const [dragDropGroups, setDragDropGroups] = useState<Maybe<any>[]>([]);
  const [dropped, setDropped] = useState<{ [key: string]: boolean }>({}); // Track success or failure for each drop target
  const { setEngagement, pageContext } = useStore();
  const totalLength = isVertical
    ? useDragDropLength(data?.dragDropGroups)
    : data?.dragDropGroups?.length;

  useEffect(() => {
    if (pageContext?.slug && window.location.pathname.includes(pageContext.slug)) {
      setEngagement({
        type: isVertical ? "verticallDragDrop" : "horizontalDragDrop",
        pageID: pageContext?.pageId,
        pageTitle: pageContext?.slug,
        workshopID: pageContext?.workshopContext?.workshop?.id,
        engagementType: "initial",
        uniqueKey: data?._key ?? "horizontalDragDrop",
      });
    }
  }, [pageContext, data, setEngagement]);

  const useAddDroppedItem = (
    uniqueId: Maybe<string> | undefined,
    parentId: Maybe<string> | undefined,
  ) => {
    const success = Boolean(uniqueId === parentId);
    if (isVertical) {
      const randomKey = Math.random().toString(36).substring(2, 15);
      if (success) {
        setDropped(prev => ({ ...prev, [randomKey!]: success })); // Track success/failure per drop target
      }
    } else {
      setDropped(prev => ({ ...prev, [parentId!]: success })); // Track success/failure per drop target
    }
    return success;
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    const droppedObject = isVertical
      ? dragDropGroups.find(group => group.assets.find(asset => asset._key == active.id))
      : dragDropGroups.find(group => group._key === active.id);

    if (isVertical) {
      useAddDroppedItem(over.id, droppedObject._key);
    } else {
      useAddDroppedItem(active.id, over.id);
    }

    if (!droppedObject) {
      return;
    }

    if (isVertical) {
      setDroppedItems(prev => ({
        ...prev,
        [over.id]: {
          id: prev[over.id]?.id
            ? Array.from(new Set([...prev[over.id].id, active.id])) // Add new id and remove duplicates
            : [active.id], // Initialize with first id
          object: droppedObject, // Store the dropped object in the drop target
        },
      }));
    } else {
      // Always store the dropped item, regardless of success
      setDroppedItems(prev => ({
        ...prev,
        [over.id]: {
          id: active.id,
          object: droppedObject,
        }, // Store the dropped object in the drop target
      }));
    }
  };

  useEffect(() => {
    const totalTargets = totalLength;
    const totalIdCount = Object.keys(droppedItems).reduce((acc, key) => {
      // Check if droppedItems[key].id is an array, if so add its length to the accumulator
      const idArray = droppedItems[key]?.id;
      return acc + (Array.isArray(idArray) ? idArray.length : 0);
    }, 0); // Initial value of accumulator set to 0
    const totalDropped = isVertical ? totalIdCount : Object.keys(droppedItems).length;

    // Only evaluate results after all targets have received a drop
    if (totalDropped === totalTargets && totalTargets > 0) {
      const successCount = Object.values(dropped).filter(item => item).length;

      if (successCount === totalTargets) {
        setSelectedModalContent(data?.successPopup);
      } else if (successCount > 0) {
        setSelectedModalContent(data?.partialPopup);
      } else {
        setSelectedModalContent(data?.errorPopup);
      }
      setIsVisible(true);
      // setTimeout(() => setDropped({}), 500); // Clear dropped after showing results to prevent re-triggering.
    }
  }, [dropped, droppedItems, data]);

  const reset = () => {
    setDroppedItems({});
    setDropped({});
    setIsVisible(false);
    setSelectedModalContent(null);
    setDragDropGroups(shuffleArray(data?.dragDropGroups)); // Shuffle groups on reset
  };

  return {
    handleDragEnd,
    setDragDropGroups,
    selectedModalContent,
    isVisible,
    setIsVisible,
    reset,
    droppedItems, // Return dropped items for use in the component
  };
};

const shuffleArray = array => {
  return array?.sort(() => 0.5 - Math.random());
};

const defaultScreenSize = {
  ipadProDown: false,
  ipadProUp: false,
  laptopUp: false,
  laptopDown: false,
  smallLaptopDown: false,
  tabletUp: false,
  tablet: false,
  tabletDown: false,
  ipadUp: false,
  ipad: false,
  ipadDown: false,
  mobileUp: false,
  mobileDown: false,
};

/**
 * Updated screenWidth hook using media query match
 *
 * @returns object with all the keys from mediaQueryWidth
 */
export function useCheckScreenSize() {
  const [screenWidth, setScreenWidth] = useState(defaultScreenSize);
  const hasMounted = useHasMounted();
  const lastExecuted = useRef<number>(Date.now());

  const checkScreenWidth = () => {
    Object.keys(mediaQueryWidth).forEach(key => {
      if (window.matchMedia(mediaQueryWidth[key as MediaQueryWidth]).matches) {
        setScreenWidth(prev => ({ ...prev, [key]: true }));
      } else {
        setScreenWidth(prev => ({ ...prev, [key]: false }));
      }
    });
  };

  const handleResize = () => {
    setTimeout(() => {
      lastExecuted.current = Date.now();
      checkScreenWidth();
    }, 1000);
  };

  useEventListener("resize", handleResize);

  useEffect(() => {
    if (hasMounted) {
      checkScreenWidth();
    }
  }, [hasMounted]);

  if (!isBrowser()) return defaultScreenSize;

  return screenWidth;
}

export const mergeUserFields = (children: Maybe<string> | undefined) => {
  if (children == null) return null;
  const { userName, user } = useStore();
  const returnName = userName || user?.firstName || "NAME";
  if (children.includes("{name}")) {
    const replace = children.replace("{name}", returnName);

    return replace;
  }
  return children;
};

export function useUserHasWatchedIntro() {
  const { setUser, user } = useStore();
  // setUser({ ...user, dashboardVideoWatched: true });
  useFirebaseUpdateUser("users", user?.uid, { dashboardVideoWatched: true });
}

export const useTabVisibility = (onVisible: any) => {
  useEffect(() => {
    if (!isBrowser()) return;
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        onVisible();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up function
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [onVisible]);
};

//ADD MERGE IN HERE

export const useTextToSpeechHooks = (blockContent: any, mergedString: string) => {
  const [loading, setLoading] = useState(false);
  const [rawString, setRawString] = useState("");
  const [processedText, setProcessedText] = useState<string>(""); // Text with resolved social poll results
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { user, socialPollAnswer } = useStore();

  const plainText = mergedString !== "" ? mergedString : blockContent && toPlainText(blockContent);
  const mergedText = mergeUserFields(plainText) ?? plainText;

  // Stop any existing audio playback
  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current = null; // Reset reference
      setIsAudioPlaying(false);
    }
  };

  // Scan block content for `socialPollResult` annotations and fetch data
  useEffect(() => {
    const fetchSocialPollResults = async () => {
      let updatedText = mergedText;

      // Loop through blockContent to find the `socialPollResult` mark
      for (const block of blockContent || []) {
        const socialPollMark = block.markDefs?.find(
          (markDef: any) => markDef._type === "socialPollResult",
        );

        if (socialPollMark) {
          const { _id } = socialPollMark.socialPoll || {};
          if (_id) {
            try {
              const answerID = socialPollAnswer?.answerID; // Get user's selected answer

              if (answerID) {
                // Await the percentage calculation
                const percentage = await useReturnSocialPollPercentage(_id, answerID);

                // Replace "X" in the text with the fetched percentage
                updatedText = updatedText.replace("X", `${percentage}%`);
              }
            } catch (error) {}
          }
          break; // Since there's only one `socialPollResult` per block, exit the loop
        }
      }

      setProcessedText(updatedText); // Update processed text with the fetched data
    };

    fetchSocialPollResults();
  }, [blockContent, mergedText, socialPollAnswer]);

  // Handle text-to-speech playback
  const handleSpeechToTextClick = async () => {
    if (!audioRef.current || audioRef.current.paused) {
      if (processedText) {
        await playText(processedText);
      } else {
        stopAudio();
      }
    } else {
      stopAudio();
    }
  };

  const playText = async (text: string) => {
    stopAudio(); // Stop any existing audio before playing new text
    setLoading(true);
    setRawString(text);

    try {
      const { data } = await axios.post("/api/text-to-speech", {
        text,
        lang: user?.language,
      });
      const audioData = new Uint8Array(data.audioContent.data);
      const blob = new Blob([audioData.buffer], { type: "audio/mp3" });
      const audio = new Audio(URL.createObjectURL(blob));

      audioRef.current = audio;

      const resetAudioRef = () => {
        audioRef.current = null;
        setIsAudioPlaying(false);
      };

      audio.addEventListener("ended", resetAudioRef);
      audio.addEventListener("pause", resetAudioRef);
      audio.playbackRate = user?.playbackSpeed ?? 1.25;

      audio.play().then(() => {
        setIsAudioPlaying(true);
      });
    } catch (error) {
      console.error("Text-to-Speech Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return { handleSpeechToTextClick, stopAudio, loading, isAudioPlaying };
};

/**
 * Handle click outside of ref object
 *
 * @param ref
 * @param handler
 */
export function useOnClickOutside(ref: RefObject<any>, handler: (args: any) => void) {
  useEffect(() => {
    if (!isBrowser) return;
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
